<template>
<!--<li class="element-title"><span>主页背景</span></li>-->
<!--<li><a>预设背景</a></li>-->
  <!--  <li><a>自定义</a></li>-->
<!--  <li class="element-title"><span>自定义书签</span></li>-->
<!--  <li><a @click="download_json()">备份Json</a></li> -->
<!--  <li><label for="files"><a>上传Json</a></label></li> -->
<!--  <input id="files" ref="refFile" style="display: none" type="file" v-on:change="upload_json">-->
<!--  <li><a @click="recover_json()">恢复默认</a></li>-->


  <!--  <li><a>登录</a></li>-->
  <!--  <li><a>云端保存</a></li>-->
  <li class="element-title"><span>关于本站</span></li>
  <div>
    <h4>站长：</h4>
    <p><a href="https://daishenghui.club">戴戴的Linux</a></p>
    <h4>博客：</h4>
    <p><a href="https://daishenghui.club">https://daishenghui.club</a></p>
    <h4>微信公众号</h4>
    <p><img src="/img/wcqrcode.jpg" alt="戴戴的Linux"></p>
    <h4>抖音</h4>
    <p><img src="/img/dyqrcode.jpg" alt="戴戴的Linux"></p>
    <h4>小红书</h4>
    <p><img src="/img/xhsqrcode.jpg" alt="戴戴的Linux"></p>
    <h4>智网云科微信公众号</h4>
    <p><img src="/img/zwykqrcode.jpg" alt="智网云科"></p>
    <h4>版权声明：</h4>
    <p>本导航页<a href="https://daishenghui.club">由戴戴的Linux</a>、<a href="https://www.zwyktech.cn">福建省智网云科科技有限公司强力驱动</a></p></div>
</template>

/*<script>
import storage from "@/utils/storage";

export default {
  name: "Setting",
  data() {
    return {
      data: "",
      setting: ""
    };
  },
  methods: {
    download_json() {
      let json_data;
      json_data = storage.get("bookmarks")
      json_data = JSON.stringify(json_data, null, 4)
      const element = document.createElement('a')
      element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(json_data))
      element.setAttribute('download', 'bookmarks.json')
      element.style.display = 'none'
      element.click()
    },
    upload_json() {
      const selectedFile = this.$refs.refFile.files[0];
      let reader = new FileReader();
      reader.readAsText(selectedFile);
      reader.onload = function () {
        try {
          storage.set("bookmarks", JSON.parse(this.result))
        } catch (error) {
          console.log("解析失败，你的文件可能有问题。")
        }


      }
    },
    recover_json() {
      storage.set("bookmarks", "")
    }
  },
}
</script>*/

<style lang="less" scoped>

</style>