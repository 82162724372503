<template>
  <div>
    <div id="menu" :class="{ on: is_on }" @click="menu_client()"><i></i></div>
    <div :class="{ closed: !is_on }" class="list">
      <ul id="setting">
        <!--<li :class="{selected:menu_selected==='bookmark'}" @click="menu_select('bookmark')"><a>书签</a></li>-->
        <li :class="{ selected: menu_selected === 'setting' }" @click="menu_select('setting')"><a>系统</a></li>
      </ul>
      <ul id="element">
        <!--<span v-if="menu_selected==='bookmark'">-->
        <!--<Bookmarks></Bookmarks>-->
        <!--</span>-->
        <span v-if="menu_selected==='setting'">
          <Setting></Setting>
        </span>
        <span v-else-if="menu_selected==='setting'">
          <Setting></Setting>
        </span>
      </ul>
    </div>
	  <div id="aplayer"></div>
    <div id="content" ref="content" @click="menu_close()">
      <div id="top-menu-list">
        <ul id="top-menu-ul">
          <li :class="{ selected: this.$route.path === '/' }">
            <router-link to="/">首页</router-link>
          </li>
          <li :class="{ selected: this.$route.path === '/nav' }">
            <router-link to="/nav">导航</router-link>
          </li>
          <!--
          <li :class="{selected:this.$route.path==='/software'}">
            <router-link to="/software">软件</router-link>
          </li>
          <li :class="{selected:this.$route.path==='/os'}">
            <router-link to="/os">系统</router-link>
          </li>
          -->
        </ul>
      </div>
      <router-view></router-view>
      <!--    <Background @background="set_background"></Background>-->
      <div id="message"></div>
      <div id="foot" style="color:#ffffff;">
	  <span id="busuanzi_container_site_pv">您是第<span id="busuanzi_value_site_pv"></span>个访问本网站的游客</span>
	  <br />
	  ©2019-{{ dynamicYear }}&nbsp;&nbsp;
        <a class="out_link" href="https://www.daishenghui.club/" target="_blank" style="color:#ffffff;">戴戴的Linux All rights reserved.</a> &nbsp;&nbsp;
        <a class="out_link beian" href="https://beian.miit.gov.cn/" target="_blank" style="color:#ffffff;">闽ICP备19002457号-1</a>&nbsp;&nbsp;
        <a href="https://beian.mps.gov.cn/#/query/webSearch?code=35010202001655" rel="noreferrer" target="_blank">
        <img src="/img/gabbalogo.png"/>
        &nbsp;
        <text style="color:#ffffff;">闽公网安备 35010202001655号</text>
        </a> &nbsp;&nbsp;
        <!--      <a href="https://github.com/zzd/Simple-Search-Page" style="font-size: 12px;" target="_blank">-->
        <!--        <span class="tag_box">v{{ version }} Vue 测试版</span></a>-->
        <br />
        <a href="https://dh.daishenghui.club" style="font-size: 12px;" target="_blank">&nbsp;&nbsp;
          <span class="tag_box" style="color:#ffffff;">戴戴的Linux导航页</span></a>
        <a href="https://www.daishenghui.club" style="font-size: 12px;" target="_blank">&nbsp;&nbsp;
          <span class="tag_box" style="color:#ffffff;">戴戴的Linux博客</span></a>
        <a href="https://github.com/StephenJose-Dai" style="font-size: 12px;" target="_blank">&nbsp;&nbsp;
          <span class="tag_box" style="color:#ffffff;">戴戴的Linux Github</span></a>
        <a href="https://gitee.com/StephenJose_Dai" style="font-size: 12px;" target="_blank">&nbsp;&nbsp;
          <span class="tag_box" style="color:#ffffff;">戴戴的Linux Gitee</span></a>
        <a href="https://www.zwyktech.cn" style="font-size: 12px;" target="_blank">&nbsp;&nbsp;
          <span class="tag_box" style="color:#ffffff;">智网云科</span></a>
      </div>
    </div>
  </div>
</template>

<script>
import packageJson from '../package.json';
// import storage from "@/utils/storage";
// import axios from "axios";
// import Bookmarks from "@/components/Bookmarks";
import Setting from "@/components/Setting";
import APlayer from "aplayer";
import zfz from "jquery";
import '@/assets/jqjs/jquery.min.js';
import '@/assets/jqjs/cookie.js';
import '@/assets/jqjs/bootstrap.min.js';
import CanvasNest from 'canvas-nest.js';
let script;
export default {
  name: "Home",
  components: { Setting },
  data() {
    return {
      version: packageJson.version,
      is_on: false,
      menu_selected: "setting",
      background: "",
    }
  },
  computed: {
    dynamicYear() {
      const currentYear = new Date().getFullYear();
      return currentYear > 1945 ? `${currentYear}` : '1314520';
    },
  },
  methods: {
    menu_client() {
      this.is_on = !this.is_on
    },
    menu_close() {
      this.is_on = false
    },
    menu_select(val) {
      this.menu_selected = val
    },
    set_background(data) {
      this.background = data;
    },
  },
  mounted() {
    this.$refs.content.style.background = this.background;
    script = require("busuanzi.pure.js");
    const ap = new APlayer({
      container: document.getElementById('aplayer'),
	  fixed: true,
      autoplay: true,
      audio: [
	{
        name: 'Purple Passion',
        artist: 'Diana Boncheva',
        url: 'https://m10.music.126.net/20230903191723/dc2eaf272f8eaaa78c22c58cd8eb4ee4/ymusic/f3c8/0960/6a8e/62b6634fb07f4f6346e92bf2394b873a.mp3',
        cover: 'https://p1.music.126.net/0pkbLZHS7MKL85qp9D6_YA==/109951165970600159.jpg?param=130y130',
      },
	  {
        name: '稻香',
        artist: '周杰伦',
        url: 'http://freetyst.nf.migu.cn/public/product5th/product35/2019/10/1420/2009%E5%B9%B406%E6%9C%8826%E6%97%A5%E5%8D%9A%E5%B0%94%E6%99%AE%E6%96%AF/%E5%85%A8%E6%9B%B2%E8%AF%95%E5%90%AC/Mp3_64_22_16/60054702010.mp3',
        cover: 'https://pic4.zhimg.com/80/v2-2f153447363fc749439c51e4543b4373_720w.jpg',
      },
	  {
        name: '下完这场雨',
        artist: '后弦',
        url: 'http://freetyst.nf.migu.cn/public/product8th/product39/2020/04/3018/2018%E5%B9%B410%E6%9C%8809%E6%97%A517%E7%82%B916%E5%88%86%E7%B4%A7%E6%80%A5%E5%86%85%E5%AE%B9%E5%87%86%E5%85%A5%E7%A8%BB%E9%A6%99%E6%96%87%E5%8C%964%E9%A6%96/%E5%85%A8%E6%9B%B2%E8%AF%95%E5%90%AC/Mp3_64_22_16/63372900462181724.mp3',
        cover: 'https://d.musicapp.migu.cn/prod/file-service/file-down/b1899d500dda5db2da11df3efc89cba6/d6ac70e448ff3cd5d545cf44d0d7a2c0/fc8bcf7cd9016412f52efe38cf838e95',
      },
	  {
        name: '初爱',
        artist: '杨宗纬 ',
        url: 'http://freetyst.nf.migu.cn/public/product9th/product41/2020/06/2315/2020%E5%B9%B401%E6%9C%8813%E6%97%A519%E7%82%B917%E5%88%86%E5%86%85%E5%AE%B9%E5%87%86%E5%85%A5%E6%AD%A3%E4%B8%9C4%E9%A6%96359938/%E5%85%A8%E6%9B%B2%E8%AF%95%E5%90%AC/Mp3_64_22_16/6005662JQJV154044.mp3',
        cover: 'https://d.musicapp.migu.cn/prod/file-service/file-down/b1899d500dda5db2da11df3efc89cba6/0d433defbffcbbdcec6b85a05e74660c/4a78d0976f62788f614a32015c92a342',
      },
	   {
        name: '飞鸟和蝉',
        artist: '任然',
        url: 'https://res.wx.qq.com/voice/getvoice?mediaid=MzI3NjYzODY0Nl8yMjQ3NDg0Mzcz',
        cover: 'https://img1.kuwo.cn/star/albumcover/500/87/25/2241738210.jpg',
      },
	  {
        name: '天外来物',
        artist: '薛之谦',
        url: 'https://ym.zwyk.eu.org:9300/5.mp3',
        cover: 'https://img1.kuwo.cn/star/albumcover/500/95/32/413716499.jpg',
      },
	  {
        name: '耗尽',
        artist: '薛之谦',
        url: 'http://freetyst.nf.migu.cn/public/product7th/productB30/2020/11/2623/2020%E5%B9%B411%E6%9C%8826%E6%97%A517%E7%82%B916%E5%88%86%E7%B4%A7%E6%80%A5%E5%86%85%E5%AE%B9%E5%87%86%E5%85%A5%E6%B5%B7%E8%9D%B6%E5%94%B1%E7%89%871%E9%A6%96/%E5%85%A8%E6%9B%B2%E8%AF%95%E5%90%AC/Mp3_64_22_16/6005860TAA0235056.mp3',
        cover: 'https://d.musicapp.migu.cn/prod/file-service/file-down/8121e8df41a5c12f48b69aea89b71dab/03e6356a979c4703156e865288877d03/d1c22f90fc05c88cf9c59bc8e7b46d7e',
      },
	{
        name: "冬眠",
        artist: '司南',
        url:'http://freetyst.nf.migu.cn/public/product8th/product38/2020/02/0412/2020%E5%B9%B401%E6%9C%8821%E6%97%A519%E7%82%B956%E5%88%86%E5%86%85%E5%AE%B9%E5%87%86%E5%85%A5%E4%B8%8A%E8%99%9E%E5%88%9B%E6%96%B01%E9%A6%96/%E5%85%A8%E6%9B%B2%E8%AF%95%E5%90%AC/Mp3_64_22_16/63898400181.mp3',
        cover: 'https://d.musicapp.migu.cn/prod/file-service/file-down/b1899d500dda5db2da11df3efc89cba6/e2c6ababa862aa7b3cc222ab21923eb2/db7e5eba6c4dfb91e73b354436a5177e',
      },
	   {
        name: '最近',
        artist: '王小帅',
        url: 'https://m10.music.126.net/20230903192137/b96fa3840ce5d403ccab8555a87c14b3/ymusic/060c/070e/5308/1f312982340d1a484d7adb3599a54fe0.mp3',
        cover: 'https://p3fx.kgimg.com/stdmusic/20190404/20190404135903379954.jpg',
      },
      {
        name: "生而为人",
        artist: '尚士达',
        url:'http://freetyst.nf.migu.cn/public/product5th/product34/2019/07/0217/2019%E5%B9%B407%E6%9C%8802%E6%97%A512%E7%82%B911%E5%88%86%E5%86%85%E5%AE%B9%E5%87%86%E5%85%A5%E5%87%AF%E5%9F%9F%E6%96%87%E5%8C%961%E9%A6%96/%E5%85%A8%E6%9B%B2%E8%AF%95%E5%90%AC/Mp3_64_22_16/63372400064.mp3',
        cover: 'https://p3fx.kgimg.com/stdmusic/20181222/20181222110124263772.jpg',
      },
      {
        name: "命运点歌",
        artist: '萧煌奇',
        url: 'http://freetyst.nf.migu.cn/public/product5th/product34/2019/07/2916/2019%E5%B9%B406%E6%9C%8827%E6%97%A501%E7%82%B932%E5%88%86%E5%86%85%E5%AE%B9%E5%87%86%E5%85%A5%E5%8D%8E%E7%BA%B375%E9%A6%96230494/%E5%85%A8%E6%9B%B2%E8%AF%95%E5%90%AC/Mp3_64_22_16/6005751WFXK.mp3',
        cover: 'https://p3fx.kgimg.com/stdmusic/20150715/20150715185445455410.jpg',
      },
	  {
        name: "心里有针",
        artist: '萧煌奇',
        url: 'https://m10.music.126.net/20230903192341/216637c5cf5853a2f24b272002108b6e/ymusic/060f/015c/010b/1eb773fc9a666d1cd6756a9dcd3effc4.mp3',
        cover: 'https://p3fx.kgimg.com/stdmusic/20150718/20150718064545147222.jpg',
      },
	  {
        name: "海阔天空",
        artist: 'Beyond',
        url: 'https://m804.music.126.net/20230903192442/9704b73c9262c163f1521108b2c6cb78/jdymusic/obj/wo3DlMOGwrbDjj7DisKw/14096591905/cdd8/f756/592f/8020efc642f1a3f0265e371637853c17.mp3',
        cover: 'https://p3fx.kgimg.com/stdmusic/20150715/20150715232800432202.jpg',
      },
	   {
        name: "光辉岁月",
        artist: 'Beyond',
        url: 'https://res.wx.qq.com/voice/getvoice?mediaid=MzI3NjYzODY0Nl8xMDAwMDA2MzE=',
        cover: 'https://p3fx.kgimg.com/stdmusic/20150715/20150715223649921352.jpg',
      },
      {
        name: '少一点天分',
        artist: '孙盛希',
        url: 'https://m704.music.126.net/20230903192638/b9a4b54696d3e3fe5d56470ed7a99f6b/jdymusic/obj/wo3DlMOGwrbDjj7DisKw/28481813007/b3aa/f8ab/296f/214b84709e7652aac4d3e2be62e46aa6.mp3',
        cover: 'https://p1.music.126.net/1QT6pMrufe1LjwVOgDJ81w==/109951163176818589.jpg?param=130y130',
      },
      {
        name: '不懂',
        artist: '贾添',
        url: 'http://cdn5.lizhi.fm/audio/2017/08/23/2620435317274550790_hd.mp3',
        cover: 'https://p3fx.kgimg.com/stdmusic/20170712/20170712175100910268.jpg',
      },
	  {
        name: '坚持',
        artist: '翁立友',
        url: 'https://tyst.migu.cn/public/product9th/product46/2022/12/0614/2022%E5%B9%B408%E6%9C%8826%E6%97%A514%E7%82%B902%E5%88%86%E7%B4%A7%E6%80%A5%E5%86%85%E5%AE%B9%E5%87%86%E5%85%A5%E6%96%B0%E8%88%AA%E9%81%9350160%E9%A6%96221837/%E6%A0%87%E6%B8%85%E9%AB%98%E6%B8%85/MP3_128_16_Stero/69031717302143421.mp3',
        cover: 'https://p3fx.kgimg.com/stdmusic/20150720/20150720130929326895.jpg',
      },
	  {
        name: '可不可以勇敢',
        artist: '张笑晨',
        url: 'http://freetyst.nf.migu.cn/public/product08/2018/03/06/%E7%A6%8F%E8%8C%82%E4%B8%89%E6%9C%88%E7%94%B3%E6%8A%A5%E6%AD%8C%E6%9B%B2/%E5%85%A8%E6%9B%B2%E8%AF%95%E5%90%AC/Mp3_64_22_16/%E5%8F%AF%E4%B8%8D%E5%8F%AF%E4%BB%A5%E4%B8%8D%E5%8B%87%E6%95%A2-%E8%8C%83%E7%8E%AE%E7%90%AA.mp3',
        cover: 'https://p3fx.kgimg.com/stdmusic/20171215/20171215115038315349.jpg',
      },
	  {
        name: '老母',
        artist: '黄明志 王光芳',
        url: 'https://ym.zwyk.eu.org:9300/1.mp3',
        cover: 'https://p3fx.kgimg.com/stdmusic/20170222/20170222071013180796.jpg',
      },
	  {
        name: '浪子回头',
        artist: '茄子蛋',
        url: 'https://ym.zwyk.eu.org:9300/2.mp3',
        cover: 'https://p3fx.kgimg.com/stdmusic/20180313/20180313084115539975.jpg',
      },
	  {
        name: '如果当时',
        artist: '许嵩',
        url: 'https://res.wx.qq.com/voice/getvoice?mediaid=MzI3NjYzODY0Nl8xMDAwMDUxNDY=',
        cover: 'https://p3fx.kgimg.com/stdmusic/20150718/20150718113957197635.jpg',
      },
	  {
        name: '有些爱情放不下',
        artist: '唐伯虎Annie',
        url: 'https://ym.zwyk.eu.org:9300/3.mp3',
        cover: 'https://p3fx.kgimg.com/stdmusic/20180313/20180313180516747569.jpg',
      },
	  {
        name: '心安理得',
        artist: '王天戈',
        url: 'http://freetyst.nf.migu.cn/public/product5th/product26/2018/12/17/2018%E5%B9%B410%E6%9C%8810%E6%97%A509%E7%82%B931%E5%88%86%E5%86%85%E5%AE%B9%E5%87%86%E5%85%A5%E8%90%A5%E9%80%A0%E7%BD%91%E6%9E%842%E9%A6%96/%E5%85%A8%E6%9B%B2%E8%AF%95%E5%90%AC/Mp3_64_22_16/63250300196.mp3',
        cover: 'https://p3fx.kgimg.com/stdmusic/20170425/20170425181010103153.jpg',
      },
	  {
        name: '庸人自扰',
        artist: '王理文',
        url: 'http://freetyst.nf.migu.cn/public/product9th/product41/2020/07/2419/2020%E5%B9%B407%E6%9C%8824%E6%97%A517%E7%82%B902%E5%88%86%E7%B4%A7%E6%80%A5%E5%86%85%E5%AE%B9%E5%87%86%E5%85%A5%E6%8E%8C%E5%8A%A8%E9%9F%B3%E8%AE%AF6%E9%A6%96/%E5%85%A8%E6%9B%B2%E8%AF%95%E5%90%AC/Mp3_64_22_16/63282100554192728.mp3',
        cover: 'https://p3fx.kgimg.com/stdmusic/20180413/20180413163824831143.png',
      },
	  {
        name: '请先说你好',
        artist: '贺一航',
        url: 'https://ym.zwyk.eu.org:9300/3.mp3',
        cover: 'https://p3fx.kgimg.com/stdmusic/20180827/20180827201952817689.jpg',
      }
	  ]
    });
    var a_idx = 0;
    zfz("body").click(function(e) {
      var a = [
        "富强",
        "民主",
        "文明",
        "和谐",
        "自由",
        "平等",
        "公正",
        "法治",
        "爱国",
        "敬业",
        "诚信",
        "友善",
        "喵喵喵",
        "汪汪汪",
        "爱你哟",
        "❤❤❤❤❤❤❤❤",
        "任何人的私生活、家庭、住宅和通信不得任意干涉，他的荣誉和名誉不得加以攻击。人人有权享受法律保护，以免受这种干涉或攻击。"
      ];
      var $i = zfz("<span/>").text(a[a_idx]);
      a_idx = (a_idx + 1) % a.length;
      var x = e.pageX,
        y = e.pageY;
      $i.css({
        "z-index": 99999,
        top: y - 20,
        left: x,
        position: "absolute",
        "font-weight": "bold",
        color: "#ff6651"
      });
      zfz("body").append($i);
      $i.animate(
        {
          top: y - 180,
          opacity: 0
        },
        1500,
        function() {
          $i.remove();
        }
      );
    });
	const config = {
      color: '255,0,0',
	  opacity: 0,
	  zIndex: -1,
      count: 999,
    };
    const cn = new CanvasNest(element, config);
    cn.destroy();
  },
  watch: {
    background() {
      try {
        this.$refs.content.style.background = this.background
      } catch (error) {
        console.log(error);
      }
    },
	$route(to, from) {
      if (to.path != from.path) {
        script.fetch();
      }
      // console.log(to.path);
    }
  },
};
</script>

<style lang='less'>
@import "style/main";
@import "APlayer/dist/APlayer.min.css";
/* 其他样式 */
</style>
